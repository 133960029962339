body {
  overflow-x: hidden;
  font-size: 14px;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

span.MuiButton-label a {
  color: #fff;
  text-decoration: auto;
}

iframe {
  position: relative !important;
}

.redio_section {
  padding: 20px;
}

.login {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-mar [type="file"] {
  /* height: 30px !important; */
  width: 300px;
  /* padding: 10px 37px 0px 3px; */
  margin-right: 160px;
}

.file-upload-custom {
  position: relative;
}

.progress-bar {
  background-color: red !important;
}

.upload__btn {
  font-size: 14px;
  cursor: pointer;
  background-color: rgb(255 0 0);
  color: white;
  padding: 10px 20px;
  border: none;
  max-width: 160px;
  margin: auto;
  width: 100%;
  position: absolute;
  left: 300px;
  top: 0px
}

.login__container {
  text-align: center;
  font-family: sans-serif;
  width: 400px;
  border-radius: 10px;
  background-color: #ffff;
  padding: 1.8rem;
  box-shadow: 2px 5px 20px rgb(0 0 0 / 10%);
  padding: 30px;
  margin-top: 150px;
}

.login__textBox {
  width: 100%;
  font-weight: 400;
  line-height: inherit;
  height: auto;
  padding: 15px 20px;
  border: none;
  outline: none;
  border-radius: 2rem;
  color: var(--color-black);
  background: #f1f5f9;
  text-transform: unset;
  text-rendering: optimizeLegibility;
}

.theme-input {
  padding: 15px 20px !important;
  border: none !important;
  border-radius: 2rem !important;
  background: #f1f5f9 !important;
}

.extra_line p {
  font-size: 14px;
}

.login__btn {
  font-size: 14px;
  cursor: pointer;
  background-color: rgb(255 0 0);
  color: white;
  padding: 13px 20px;
  border-radius: 20px;
  border: none;
  /* max-width: 160px;     */
  margin: auto;
  width: 100%;
}

.book__btn {
  font-size: 14px;
  cursor: pointer;
  background-color: rgb(255 0 0);
  color: white;
  padding: 7px;
  border-radius: 20px;
  border: none;
  margin: auto;
  width: 100px;
}

.form_head h2 {
  margin: 0px;
}

span.striped-text {
  margin: 0 10px;
  font-size: 13px;
}

.striped {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.striped span.striped-line {
  flex: auto;
  flex-basis: auto;
  border: none;
  outline: none;
  height: 1px;
  background: #ddd;
}

.profile_picture img {
  border-radius: 50%;
  object-fit: cover;
}

.form_head {
  margin: 10px 0px;
}

.form_head p {
  font-size: 13px;
}

button.login__google {
  background: #fff;
  border: solid 2px rgb(245 239 239);
  border-radius: 20px;
  display: flex;
  padding: 10px 10px;
  flex: auto;
  align-items: center;
  gap: 5px;
  justify-content: center;
  color: red;
  font-weight: 200;
  font-size: 14px;
  margin: auto;
}

.form_head p a {
  color: red;
}

button.login__google img {
  width: 20px;
}

/* .login div {
  margin-top: 7px;
} */
.register {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.register__textBox {
  padding: 10px;
  font-size: 13px;
  margin-bottom: 10px;
}

.register__btn {
  padding: 10px;
  font-size: 13px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: red;
  cursor: pointer;
}

.register__google {
  background-color: #4285f4;
}

.register div {
  margin-top: 7px;
}




button.dashboard__btn {
  font-size: 14px;
  cursor: pointer;
  background-color: rgb(255 0 0);
  color: white;
  padding: 13px 20px;
  border-radius: 20px;
  border: none;
  max-width: 160px;
  margin: auto;
  width: 100%;
}

.login_form_box {
  background: #fff;
  border-radius: 6px;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 10%);
  max-width: 840px;
  overflow: hidden;
}

/* .dashboard div {
  margin-top: 7px;
} */

/*profile page css*/
.profile_box {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: sans-serif;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  background-color: #ffff;
  box-shadow: 2px 5px 20px rgb(0 0 0 / 10%);
  padding: 20px;
  min-height: 250px;
  height: auto;
  border: 2px solid red;
}

.profile_box a .MuiPaper-root {
  box-shadow: none;
}

ul.category_list_item li a img {
  width: 120px;
}

ul.category_list_item li a h3 {
  color: #000;
  margin-bottom: 20px;
  white-space: nowrap;
}

/*home Page*/
ul.category_list_item li {
  list-style: none;
  background: #fff;
  width: 10%;
  margin: 7px 0;
}

ul.category_list_item li a {
  text-decoration: auto;
  padding: 10px;
}

ul.category_list_item li a h4 {
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
}

ul.category_list_item {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  justify-content: center;
  padding: 0;
}


ul.category_list_item li:hover h4 {
  color: red;
}

ul.category_list_item li:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: 0.2s;
}

ul.category_list_item li a {
  display: block;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 1px solid #ff1616;
  border-radius: 4px;
}

.rc_searchbox_wrap {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  z-index: 1;
}

.rc_searchbox {
  display: flex;
  width: 650px;
  max-width: 100%;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-top: 10px;
}

.rc_searchbox img {
  position: absolute;
  left: 10px;
}

.rc_searchbox #search,
.rc_searchbox #search:focus,
.rc_searchbox #search:focus-visible {
  width: 650px;
  max-width: 100%;
  height: 55px;
  border-radius: 5px;
  font-size: 16px;
  padding: 0 20px 0 40px;
  border: 1px solid #ff1616 !important;
  outline: none !important;
}

.rc_seachresults {
  position: absolute;
  top: 100%;
  width: 650px;
  max-width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* height: 380px;
  overflow-y: scroll; */
}

.rc_seachresults .box-search {
  width: 100%;
  display: flex;
}

.rc_seachresults .box-search a {
  width: 100%;
  display: flex;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-size: 14px;
  padding: 5px 20px;
  background-color: transparent;
}

.rc_seachresults .box-search a:hover {
  background-color: #f5f5f5;
}

.top-header-bar {
  padding: 50px 0px 30px 0px;
}

.top-header-bar img {
  width: auto;
}

.reci-details span,
.reci-details .col-xl-4 {
  white-space: nowrap;
}

.reci-details img {
  margin-right: 5px;
  position: relative;
  bottom: 2px;
}

.recis_video_box .MuiPaper-root {
  margin-bottom: 20px;
}

.recis_video_box {
  font-size: 14px;
  width: 100%;
}

ul.category_list_item li a h4 {
  font-size: 14px;
  text-decoration: none;
  color: #000;
  font-weight: 500;
  white-space: nowrap;
}

.recis_video_box_detail {
  padding: 15px;
  position: relative;
  box-sizing: border-box;
  text-align: center;
}

.recis_video_box_detail button,
.social-icons button {
  margin: 15px 6px 0px 0px;
}

.recis_video_box .MuiPaper-rounded div video {
  border: none;
}

.recis_video_box .MuiPaper-elevation1 {
  box-shadow: none;
  display: flex;
  flex-direction: column;
  border: 1px solid #ff1616;
}

.recis_video_box .MuiPaper-elevation1 p {
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

/*instagram*/
.instagram_profile img {
  width: 130px;
  border-radius: 50%;
}

.media_btn a.active {
  background: red;
  color: #fff;
}

.instagram_feed_photo .MuiGrid-item {
  padding: 15px;
}

.media_btn a {
  margin: 10px;
  text-decoration: none;
  background: #ff000073;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}

.media_btn {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.insta_feed_box .MuiGrid-item {
  margin: auto;
}

.instagram_feed_photo {
  margin: 20px 0px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.profile_list .MuiButton-root {
  padding: 0px;
  min-width: 48px;
  min-height: 48px;
  border: 1px solid #f5f5f5;
}

/* CookBook Page CSS */
.cookbook_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  margin: 15px;
  box-shadow: none !important;
  border: 1px solid #ff0000;
  min-height: 430px;
}

.cookbook_box p {
  margin-top: 0;
  width: 100%;
  text-align: center;
}

.cookbook_box .MuiButton-text {
  width: 100%;
  padding: 0;
}

.cookbook_box .button_cookbook {
  padding: 8px 15px;
  text-transform: capitalize;
}

.cookbook_box .button_cookbook:hover {
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  transition: 0.2s;
}

.cookbook_box .MuiButton-label img {
  width: 100%;
  height: 210px;
  object-fit: cover;
}

.cookbook_tagname {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  padding: 5px 0 15px 0;
  justify-content: center;
}

.cookbook_tagname p {
  margin: 0;
  height: auto;
  width: auto;
}

.cookbook_tagname p a {
  display: flex;
  width: auto;
  color: #000000;
  background-color: #f1f1f1;
  border-radius: 50px;
  text-decoration: none;
  padding: 5px 10px;
  font-size: 12px;
}

/*CookBook Select Page CSS*/
.cookbookselect_top {
  display: flex;
  align-items: flex-start;
  column-gap: 30px;
}

.cookbook_select {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  box-shadow: none !important;
  border: 1px solid #ff0000;
}

.cookbook_select p {
  width: 100%;
  text-align: left;
  margin-top: 0;
}

.cookbook_select div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 10px 0 !important;
  column-gap: 6px;
  row-gap: 6px;
}

.cookbook_select div p {
  width: auto;
  margin: 0;
}

.cookbook_select div h3 {
  margin: 0;
  font-size: 16px;
}

.cookbook_select div p a {
  display: flex;
  width: auto;
  color: #000000;
  background-color: #f1f1f1;
  border-radius: 50px;
  text-decoration: none;
  padding: 5px 10px;
  font-size: 12px;
}

.cookboomk_main {
  display: flex;
}

.cookbook_selecttoprec {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  box-shadow: none !important;
  border: 1px solid #ff0000;
  text-align: center;
}

.cookbook_selecttoprec p {
  margin-top: 0;
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  column-gap: 10px;
  justify-content: center;
}

.cookbook_selecttoprec .mx-0.row {
  width: 100%;
}

.cookbook_selecttoprec .profile_list {
  padding: 30px 0 15px 0;
}

.cookbook_selecttoprec .profile_list .MuiButton-root {
  margin: 0 5px;
}

.social_list button {
  margin: 0 3px;
}

/*------------------ Responsive CSS ------------------*/
@media (min-width: 1025px) and (max-width: 1300px) {
  ul.category_list_item li {
    width: 15%;
  }

  ul.category_list_item li a img {
    width: 80px;
  }

  ul.category_list_item li a h4 {
    margin: 10px 0;
  }
}

@media (max-width: 1024px) {
  ul.category_list_item li {
    width: 15%;
  }

  ul.category_list_item li a img {
    width: 80px;
  }

  ul.category_list_item li a h4 {
    margin: 10px 0;
  }
}

@media (max-width: 991px) {
  ul.category_list_item li {
    width: 18%;
  }

  .cookbookselect_top {
    flex-direction: column;
    row-gap: 20px;
  }

  .cookbook_select {
    width: auto;
  }

  .cookbook_selecttoprec {
    width: auto;
  }
}

@media (max-width: 767px) {
  ul.category_list_item li {
    width: 45%;
  }
}

/* @media (max-width: 619px) {    */
@media (max-width: 620px) {

  .reci-detail {
    /* padding-top: 120px; */
    width: 95% !important;
  }

  ul.category_list_item {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  ul.category_list_item li a h3 {
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0;
  }

  .mobile_view {
    top: 70px !important;
    height: auto !important;
    padding-bottom: 20px;
  }

  .recis_video_box .MuiPaper-elevation1 p {
    font-size: 13px;
  }

  .recis_video_box_detail button svg {
    width: 24px;
    height: 24px;
  }

  .profile_list .MuiButton-root {
    min-width: 38px;
    min-height: 38px;
  }

  .profile_list .MuiButton-root .MuiButton-label img {
    width: 24px;
    height: 24px;
  }
}

.modal {
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

#myModal .modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  max-width: 269px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  border-radius: 7px;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

#myModal .modal-header {
  padding: 2px 16px;
  background-color: #fff;
  color: white;
}

#myModal .modal-body {
  padding: 2px 16px;
}

.btn-yes {
  background: green !important;
  color: white !important;
}

#myModal .modal-footer {
  padding: 2px 16px;
  background-color: transparent;
  color: white;
  padding-bottom: 10px;
}

.errormsg {
  color: red;
}

.reci_headerss {
  display: flex;
  justify-content: center;
  gap: 11px;
  align-items: center;
}

.reci-tools a,
.reci-tools button {
  width: 50px !important;
}

.reci-tools {
  text-align: center;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
}

.theme-bg {
  background-color: red !important;
  color: white !important;
  cursor: pointer !important;
}

.searchbar input {
  border: none;
  width: 92%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
}

.searchbar .input-group-text {
  border: none;
  background: none;
  width: 8%;
}

.searchbar {
  max-width: 550px !important;
  margin: auto;
  border: red 1px solid;
  border-radius: 5px;
  height: 50px;
}

.card-container {
  width: 75%;
  margin: auto;
  border: none;
  text-align: start;
}

.card-container .box-container {
  padding: 50px;
}

.card-container .box-container:first-child {
  border-right: 1px solid #e0e0e0;
}

.title-1 {
  font-size: 32px;
}

.title-2 {
  font-size: 22px;
}

.booking-calendar {
  border: none !important;
  width: 100% !important;
  margin-top: 30px;
}

.time-slot {
  padding: 10px;
  border: 1.5px solid #e0e0e0;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.time-slot:hover {
  border-color: red;
}

.time-slot.active {
  border-color: red;
}

.time-slot-container {
  overflow-y: auto;
  max-height: 330px;
  scrollbar-width: thin;
}

.booking-page .theme-button {
  width: 100%;
}

.react-calendar abbr {
  text-decoration: none !important;
}

.booking-page {
  min-height: 550px;
}

.booking-page .fa-arrow-left {
  font-size: 20px;
  color: red;
  border: 1px solid gray;
  border-radius: 50%;
  padding: 8px 10px;
  cursor: pointer;
}

.custom-spinner {
  width: 1.2rem !important;
  height: 1.2rem !important;
  border-width: 3px !important;
  margin-left: 5px;
}

.page-container {
  width: 100%;
  padding: 85px 20px 20px 265px;
  text-align: start;
}

.web-container {
  padding: 20px;
  margin-top: 65px;
}

.custom-sidebar {
  height: 56;
}

.custom-sidebar .header {
  background: red;
  box-shadow: none;
  color: #fff;
}

.drawer .custom-link {
  color: #000;
}

.drawer .custom-link:hover {
  color: red;
}

.drawer .custom-link i {
  color: red;
  font-size: 20px;
  margin: 0px 5px 0px 2px;
}

.drawer .custom-link img {
  margin-right: 5px;
  width: 24px;
}

.drawer ul li {
  cursor: pointer;
}

@media (max-width: 600px) {
  .page-container {
    padding: 20px;
    margin-top: 65px;
  }

  .web-container {
    padding: 10px;
    margin-top: 65px;
  }

  .searchbar input {
    border: none;
    width: 80%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
  }

  .searchbar .input-group-text {
    border: none;
    background: none;
    width: 20%;
  }
}


.custom-link {
  color: #ffffffd7;
  padding: 10px;
  text-decoration: none;
}

.header .custom-link:hover {
  color: #fff;
  padding: 10px;
}

.profile-img {
  border-radius: 50%;
  cursor: pointer;
}

#account-menu i {
  color: red;
  font-size: 18px;
}

.booking-page .box-container i,
.custom-dropdown .dropdown-item i {
  color: red;
  margin-right: 5px;
}

.stripe-container {
  text-transform: capitalize;
  text-align: center;
  margin-top: 10%;
}

.title-3 {
  font-size: 26px;
}

.modal {
  z-index: 1201 !important;
}

.product-card .card-body {
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card p {
  margin-bottom: 0;
}

.title-4 {
  font-size: 16px;
  font-weight: bold;
}

.title-5 {
  font-size: 14px;
}

.product-card .fa-clock-o {
  margin-right: 5px;
}

.custom-dropdown .dropdown-toggle {
  background: none !important;
  color: #000 !important;
  border: none !important;
}

.custom-dropdown .dropdown-toggle::after {
  display: none !important;
}

.product-card:hover {
  cursor: pointer;
  border: 1px red solid;
}

.eventPage .fa-eye {
  color: red;
  cursor: pointer;
}

.colon {
  margin-right: 10px;
}

.eventPage {
  word-wrap: break-word;
}

.spinner-container {
  position: absolute;
  top: 45%;
  left: 50%;
  z-index: 100000 !important;
}

.spinner-container_wapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1999 !important;
  backdrop-filter: blur(5px);
}

.page-spinner {
  color: red;

}