/* .dashboard_main{
    padding: 100px;
} */
.form_row input {
    height: 40px;
    border: 1px solid #ddd;
}

.nutrition_input_field_row input {
    width: 100%;
}

.ingredients_list_item {
    display: flex;
    align-items: center;
    gap: 40px;
    margin: 10px 0px;
}

.form_row textarea {
    width: 100%;
    border: 1px solid #ddd;
    height: 100px;
    margin: 10px 0px;
}

.ingredients_list {
    text-align: left;
}

.ingredients_list_item button {
    border: 1px solid red;
    display: block;
    background: red;
    padding: 10px 20px;
    color: #fff;
}

/* .dashboard_main {
    margin: auto;
    padding: 100px;
} */
.form_row {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ingredients_field .form_row {
    display: flex;
    gap: 10px;
}

.field_label {
    margin-bottom: 10px;
}

.ingredients_field table {
    width: 100%;
    margin-bottom: 5px;
}

.ingredients_field tr td,
.ingredients_field tr th {
    border: 1px solid #ccc;
    padding: 5px;
}

.author_detail_field {
    display: flex;
    gap: 10px;
}

.nutrition_input_field_row {
    display: flex;
    gap: 10px;
}

.nutrition_input_field_label p {
    margin: 6px 0px;
}

.nutrition_input_field_label {
    text-align: left;
}

/* .ingredients_field {
    background: #ddd;
    padding: 10px 10px;
} */

.ingredients_field {
    text-align: left;
}

.author_detail_field input {
    width: 100%;
    margin-top: 10px;
}

.recipe_main-heading h4 {
    color: #fff;
    margin: 0;
    font-size: 20px;
}

.recipe_main-heading {
    display: flex;
    justify-content: space-between;
    padding: 6px 10px;
    margin-bottom: 1rem;
    background: red;
    align-items: center;
}

.recipe_main-heading .addButton {
    background: #fff;
    padding: 0px 5px;
}

.form_row select {
    height: 40px;
    border: 1px solid #ddd;
    width: 100%;
    margin: 10px 0px;
}

.ingredients_field_inner input {
    width: 100%;
}

.ingredients_field_inner {
    display: flex;
    gap: 10px;
    align-items: center;
}

button.addbtn {
    background: red;
    border: 1px solid #ddd;
    color: #fff;
    font-size: 40px;
    height: 40px;
    margin-top: 5px;
    line-height: 0px;
}


.dashboard_main .dashboard_main_grid {
    margin: auto;
}

.form_row.video_field img {
    width: 70px;
    cursor: pointer;
}

textarea:focus,
input:focus {
    outline: none;
}

.form_error p {
    color: red;
    font-weight: 600;
}

.form_error {
    text-align: left;
}

.invalid-feedback {
    text-align: start;
}

/* .form-control, .form-select{
    margin-bottom:20px
} */
.reci-detail {
    /* padding-top: 120px; */
    margin: auto;
    width: 100%;
    text-align: center;
}

.reci-detail__Ingredients {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

.recis_video_box_detail .fa-edit {
    color: white;
    background: red;
    padding: 8px;
    border-radius: 5px;

}

.editReci {
    position: absolute;
    right: 15px;
}

.theme-button {
    background-color: red;
    cursor: pointer;
    min-width: 130px;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 7px;
}

.theme-button-secondary {
    background-color: gray;
    cursor: pointer;
    width: 130px;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 7px;
}